import React, {useEffect} from "react"
import ReactDOM from "react-dom"
import ModalWrapper from "./shared"
import AudioDescription from "src/components/AudioDescription"
import AudioPlayer from "src/components/AudioPlayer"
import PhotoFullscreen from "../PhotoFullscreen/desktop"

const Modal = ({ children, open, handlerClose, photoSrc, audioArray }) => {
  return (
    <ModalWrapper open={open} handlerClose={handlerClose} photoSrc={photoSrc}>
      {children}
    </ModalWrapper>
  )
}

const Portal = ({
  children,
  open,
  handlerClose,
  photoSrc,
  audioArray,
  photoFullscreen,
}) => {
  let portalRoot

 

  const isPortalRoot = typeof document !== `undefined`
  if (isPortalRoot) {
    portalRoot = document.getElementById("portal")
  } else {
    return false
  }

  return ReactDOM.createPortal(
    photoFullscreen ? (
      <PhotoFullscreen
        open={open}
        handlerClose={handlerClose}
        photoSrc={photoSrc}
      />
    ) : (
      <Modal open={open} handlerClose={handlerClose} photoSrc={photoSrc}>
        {children}
        {audioArray?.map((audio, index) => (
          <div key={audio?.UID}>
            <AudioDescription
              content={audio?.Description}
              noBreak
              key={audio?.UID}
              modal={true}
            />
            <AudioPlayer src={audio?.Audio} width={50} modal />
          </div>
        ))}
      </Modal>
    ),
    portalRoot
  )
}

export default Portal
