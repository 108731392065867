import React, { useEffect, useRef, useState } from "react"
import {
  Content,
  Logo,
  Nav,
  NavLink,
  NavElement,
  NavList,
  Wrapper,
  ContentScrollable,
  TitlePage,
  ScrollContainer,
  ModalOpener,
} from "./styles"
import LogoPNG from "../../../images/logo.png"
import LogoPR from "../../../images/logoPR.png"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"
import { Link } from "gatsby"
import { Title, Text } from "src/components/ArticleRow/styles"
import Modal from "src/components/Modal/Modal"

const Layout = ({ children, bg, title }) => {
  const scrollableNodeRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const element = scrollableNodeRef.current
    const scrollAction = e => {
      if (e.key == "ArrowDown") {
        element.scrollTop += 20
      }
      if (e.key == "ArrowUp") {
        element.scrollTop -= 20
      }
    }
    window.addEventListener("keydown", scrollAction, false)

    return () => window.removeEventListener("keydown", scrollAction, false)
  }, [scrollableNodeRef])
  return (
    <Wrapper bg={bg}>
      <Nav role="menu">
        <Link to="/" style={{ width: "38%", height: "auto" }}>
          <Logo src={LogoPNG} alt="Logo 95 Lat Polskiego Radia" />
        </Link>
        <NavList>
          <NavElement>
            <NavLink
              aria-label="Początek Radia"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/poczatek-radia"
              partiallyActive={true}
            >
              początek radia
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="Historia ma głos"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/historia-ma-glos"
              partiallyActive={true}
            >
              HISTORIA MA GŁOS
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="Teatr Wyobraźni"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/teatr-wyobrazni"
              partiallyActive={true}
            >
              teatr wyobraźni
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              activeStyle={{ opacity: "1" }}
              fade
              to="/glosy-radiowego-teatru"
              aria-label="Głosy radiowego teatru"
              role="menuitem"
              partiallyActive={true}
            >
              Głosy radiowego teatru
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="głosy pisarzy"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/glosy-pisarzy"
              partiallyActive={true}
            >
              głosy pisarzy
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="radiowa filharmonia"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/radiowa-filharmonia"
              partiallyActive={true}
            >
              radiowa filharmonia
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="sport w polskim radiu"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/sport"
              partiallyActive={true}
            >
              sport w polskim radiu
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="radio dzieciom"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/radio-dzieciom"
              partiallyActive={true}
            >
              radio dzieciom
            </NavLink>
          </NavElement>
          <NavElement>
            <NavLink
              aria-label="Do usłyszenia, do zobaczenia"
              role="menuitem"
              activeStyle={{ opacity: "1" }}
              fade
              to="/do-uslyszenia-do-zobaczenia"
              partiallyActive={true}
            >
              do usłyszenia, do zobaczenia
            </NavLink>
          </NavElement>
        </NavList>
        <NavList
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          <NavElement style={{ margin: "auto 0" }}>
            <ModalOpener aria-label="Źródła" tabIndex="0  "
              onClick={() => setModalOpen(true)}>Źródła</ModalOpener>
            <Modal
              open={modalOpen}
              photoSrc={""}
              handlerClose={() => setModalOpen(false)}
              audioArray={[]}
            >
              <Title 
                style={{
                  color: "#020417",
                  display: "block",
                  marginBottom: "3rem",
                }}
              >
                Źródła:
              </Title>

              <Text style={{ color: "#020417", display: "block" }}>
                <p>
                  Zdjęcia pochodzą ze zbiorów: Polskiej Agencji Prasowej, East
                  News, Agencji Forum, Narodowego Archiwum Cyfrowego oraz
                  Archiwum Polskiego Radia
                </p>
                <p>
                  Cytaty pochodzą z pracy Marcina Hermanowskiego "Radiofonia w
                  Polsce. Zarys dziejów". Poznań 2018
                </p>
                <p>
                  oraz książki Zbigniewa Chomicza "80 lat Polskiego Radia.
                  Kalendarium". Warszawa 2005.
                </p>
              </Text>
            </Modal>
          </NavElement>
        </NavList>

        <a
          href="https://www.polskieradio.pl"
          style={{ width: "38%", height: "auto" }}
        >
          <Logo src={LogoPR} alt="Logo Polskiego Radia" />
        </a>
      </Nav>
      <Content>
        <ContentScrollable>
          <TitlePage>{title}</TitlePage>
          <SimpleBar
            className="slide"
            style={{ maxHeight: "100vh" }}
            scrollableNodeProps={{ ref: scrollableNodeRef }}
          >
            <ScrollContainer
              style={{ padding: "10rem 0", paddingRight: "10rem" }}
            >
              {children}
            </ScrollContainer>
          </SimpleBar>
        </ContentScrollable>
      </Content>
    </Wrapper>
  )
}

export default Layout
