import styled, { css } from "styled-components"

export const Text = styled.div`
  color: ${({ modal }) => (modal ? "#06101d" : "#fdfdfd")};
  font-family: "Open Sans";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 2rem;
  display: inline;

  ${({ modal }) =>
    !modal &&
    css`
      .clamp-lines {
        @media (max-width: 1024px) {
          width: 85%;
        }
      }
    `}

  & .clamp-lines__button {
    background-color: #f93737;
    color: #fdfdfd;
    border: none;
    border-radius: 2rem;
    width: 4.6rem;
    text-align: left;
    padding: 0.1rem 0;
    padding-left: 1rem;
    font-weight: 600;
    font-size: 1rem;

    height: 2rem;
    margin-left: 100%;
    transform: translate(-100%, -100%);
    &[aria-expanded="true"] {
      transform: translate(-100%, 0%);
    }
    cursor: pointer;
    &:active,
    &:focus {
      outline: none;
    }
  }
`
