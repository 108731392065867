import React, { useState, useRef, useCallback } from "react"
import Player from "react-player"
import playIcon from "src/images/play.svg"
import pauseIcon from "src/images/pause.svg"
import {
  Wrapper,
  PlayButtonContainer,
  PlayButton,
  Icon,
  ProgressBarContainer,
  ProgressBar,
  Duration,
  Progress,
} from "./styles"

const AudioPlayer = React.memo(({ src = "", modal = false } = {}) => {
  const [isPlaying, setPlaying] = useState(false)
  const [iconIsPlaying, setIconIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const playerRef = useRef()

  const handlePlay = useCallback(() => {
    playerRef.current.handleClickPreview()
    setPlaying(!isPlaying)
    setIconIsPlaying(!iconIsPlaying)
  }, [playerRef.current, isPlaying, iconIsPlaying])

  const handleSeek = useCallback(
    e => {
      e.preventDefault()
      const initialPlaying = isPlaying
      if (isPlaying) setPlaying(false)
      const currentTargetRect = e.currentTarget.getBoundingClientRect()
      const targetWidth = e.currentTarget.offsetWidth
      const clickedProgress = (e.pageX - currentTargetRect.left) / targetWidth
      playerRef.current.seekTo(clickedProgress, "fraction")

      const mouseMove = move => {
        const draggedProgress =
          (move.pageX - currentTargetRect.left) / targetWidth
        playerRef.current.seekTo(draggedProgress, "fraction")
      }

      const endSeeking = () => {
        document.removeEventListener("mouseup", endSeeking)
        document.removeEventListener("mousemove", mouseMove)
        setPlaying(initialPlaying)
      }

      document.addEventListener("mouseup", endSeeking)
      document.addEventListener("mousemove", mouseMove)
    },
    [isPlaying]
  )

  const handleProgress = ({ played }) => {
    setProgress(played)
    if (played === 1) {
      setPlaying(false)
      setIconIsPlaying(false)
    }
  }

  const convertSecs = seconds => {
    const date = new Date(0)
    date.setSeconds(seconds)
    return date.toISOString().substr(12, 7)
  }

  return (
    <Wrapper modal={modal}>
      <PlayButtonContainer modal={modal}>
        <PlayButton             onClick={handlePlay}
>
          <Icon tabindex="0"
            style={{ zIndex: "5 !important" }}
            src={iconIsPlaying ? pauseIcon : playIcon}
            alt={iconIsPlaying ? "Zatrzymaj" : "Odtwarzaj"}
          />
        </PlayButton>
      </PlayButtonContainer>
      {iconIsPlaying && (
        <ProgressBarContainer>
          <ProgressBar
            modal={modal}
            progress={progress * 100}
            onMouseDown={handleSeek}
          />
          <Progress modal={modal}>
            {convertSecs(playerRef.current?.getCurrentTime() || 0)}
          </Progress>
          <Duration modal={modal}>{convertSecs(duration)}</Duration>
        </ProgressBarContainer>
      )}
      <Player
        ref={playerRef}
        width={0}
        height={0}
        url={src}
        playing={isPlaying}
        progressInterval={100}
        onProgress={handleProgress}
        onDuration={dur => setDuration(dur)}
        light
        playIcon={<i />}
      />
    </Wrapper>
  )
})

export default AudioPlayer
