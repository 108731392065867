import React, { useState, useEffect, useRef } from "react"
import {
  Wrapper,
  CloseButton,
  Mask,
 
  TopFade,
  BottomFade,
  FadeWrapper,
  Photo,
  PaddingWrapper,
} from "./styles"
import { AnimatePresence } from "framer-motion"
import { fadeVariant, unfoldVariant } from "./styles/animations"

const PhotoFullscreen = ({ open, handlerClose, children, photoSrc }) => {
  const [topFade, setTopFade] = useState(false)
  const [bottomFade, setBottomFade] = useState(false)

  function escapeHandler({ key }) {
    if (key === "Escape") {
      return handlerClose()
    }
  }
  const mask = useRef();

  useEffect(() => {
    window.addEventListener("keyup", escapeHandler)

    return () => {
      window.removeEventListener("keyup", escapeHandler)

    }
  }, [])

  const curtainCLick = e => {
    if (e.target !== mask.current) {
      return
    }
    return handlerClose()
  }
  
  return (
    
  
    <AnimatePresence>
      {open && (
        <Mask  initial="exit" animate="enter" exit="exit" variants={fadeVariant}>
          <Wrapper
          onClick={(e)=>{curtainCLick(e)}}
            initial="exit"
            ref={mask}
            animate="enter"
            exit="exit"
            variants={unfoldVariant}
            isPhoto={photoSrc}
          >
            <CloseButton onClick={handlerClose}>X</CloseButton>
            {photoSrc && <Photo src={photoSrc} />}
            
          </Wrapper>
        </Mask>
      )}
    </AnimatePresence>
  )
}

export default PhotoFullscreen;
