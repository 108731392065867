import styled from "styled-components"

import { Link } from "gatsby"

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  background: black;
  background: ${({ bg }) => bg};
  background-size: cover;
  background-position: center center;
  transition: 0.3s;
`

export const Nav = styled.nav`
  width: 15%;
  height: 100%;
  display: grid;
  grid-template-rows: 2fr 2fr 2fr 1fr;
  align-items: center;
  padding-left: 5rem;
`

export const NavList = styled.ul`
  align-self: start;
  color: #fdfdfd;
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  list-style-position: inside;
  /* list-style-type: decimal; */
  letter-spacing: 3.16px;
  margin-top: -15%;
`

export const NavElement = styled.li`
  width: 100%;
  margin: 10% 0;
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  opacity: 0.62;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
`

export const Logo = styled.img`
  width: 100%;
  height: auto;
`

export const Content = styled.div`
  width: 85%;
  height: 100%;
`

export const TitlePage = styled.p`
  color: #f6f6f6;
  font-family: Montserrat;
  font-size: 0.6vw;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3.16px;
  position: absolute;
  right: -9.6vw;
  top: 16%;
  transform: rotate(90deg);
  white-space: nowrap;
  width: 20vw;
  text-align: right;

  @media (min-width: 1600px) {
    right: -9.66vw;
  }
`

export const ContentScrollable = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 100%;
  position: relative;

  .slide {
    overflow: auto;
    position: relative;

    .simplebar-vertical {
      height: 35%;
      transform: translateY(120%);
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
        height: 100%;
        transform: translateX(-50%);
        background-color: #fdfdfd;
      }
    }

    .simplebar-scrollbar {
      background: #f93737;
      border-radius: 10px;
      &::before {
        display: none;
      }
    }
  }
`

export const ScrollContainer = styled.div`
  padding-right: 5rem;
`

export const ModalOpener = styled.button    `
background-color: transparent;
opacity: 0.62;
cursor: pointer;
transition: 0.3s;
font-family: Montserrat;
font-size: 1rem;
font-weight: 400;
letter-spacing: 3.16px;
text-decoration: none;
color: inherit;
opacity: 0.62;
transition: 0.3s;
display: block;
border: none;
text-transform: uppercase;
`
