import styled from "styled-components"
import { motion } from "framer-motion"
import SimpleBar from "simplebar-react"

export const Mask = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;

  @media (max-width: 1024px) {
    z-index: 20;
  }
`

export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-50%, -50%);

  height: 100vh;
  width: 100vw;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CloseButton = styled.button`
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 4.75rem;
  font-weight: 300;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 13rem;
  top: 1rem;
  @media (max-width: 1024px) {
    right: 1rem;
    top: 1.5rem;
  }
`

export const TopFade = styled(motion.div)`
  position: absolute;
  height: 20%;
  width: 100%;
  background-color: black;
  z-index: 1;
  background: linear-gradient(to top, transparent 0%, #e3e4dd 100%);
  transition: opacity 0.3s;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: none;
  top: -1px; //gap fix
`

export const BottomFade = styled(motion.div)`
  position: absolute;
  height: 20%;
  width: 100%;
  bottom: 0;
  background-color: black;
  z-index: 1;
  background: linear-gradient(to bottom, transparent 0%, #e3e4dd 100%);
  transition: opacity 0.3s;
  opacity: ${props => (props.show ? "1" : "0")};
  pointer-events: none;
  bottom: -1px; //gap fix
`

export const Photo = styled.img`
  object-fit: cover;
  border: 1rem solid #ffffff;
  height: 60vh;
  margin-left: 8%;
  @media (max-width: 1024px) {
    margin-left: 0%;
    height: auto;
  }
`
