import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { motion } from "framer-motion"

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: ${({ bg }) => bg};
  background-size: cover;
  background-position: center;
  position: relative;
`

export const MenuButton = styled.div`
  position: fixed;
  top: 5%;
  right: 10%;
  z-index: 3;
`

export const WrapperScroll = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

export const Nav = styled(motion.nav)`
  width: 100%;
  height: 100%;
  background: #f93737;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 2;
`

export const NavList = styled.ul`
  color: #fdfdfd;
  font-family: Montserrat;
  font-size: clamp(1rem, 3.5vw, 2rem);
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  /* height: 50%; */
  margin: auto;
  width: 85%;
  letter-spacing: 4.37px;
  text-align: center;
`

export const NavElement = styled.li`
  padding: 1.5em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.19);
  width: 100%;
`

export const NavLink = styled(AniLink)`
  text-decoration: none;
  color: black;
  width: 80%;
`
