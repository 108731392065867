export const transition = {
  ease: [0.82, 0.0, 0.195, 1.0],
  duration: 1,
}

export const fadeVariant = {
  exit: {
    opacity: 0,
    transition,
  },
  enter: {
    opacity: 1,
    transition,
  },
}

export const unfoldVariant = {
  exit: {
    clipPath: "inset(0 50%)",
    transition,
  },
  enter: {
    clipPath: "inset(0 0%)",
    transition,
  },
}
