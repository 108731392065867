import styled from "styled-components"
import audioWave from "src/images/audiowave.png"
import darkWave from "src/images/audiowave_dark.png"
import { isMobile } from "react-device-detect"

export const Wrapper = styled.div`
  width: 22rem;
  height: 17rem;
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: ${({ modal }) => (modal ? "100%" : "85%")};
    height: 19rem;
  }
`
export const PlayButtonContainer = styled.div`
  width: 100%;
  background-image: ${({ modal }) =>
    modal ? `url(${darkWave})` : `url(${audioWave})`};
  height: 13rem;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: ${({ modal }) => (modal ? `contain` : `100%`)};

  @media (max-width: 1024px) {
    height: 15rem;
  }
`
export const Icon = styled.img`
  height: 9rem;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
${
  !isMobile &&
  "&:hover {transform: scale(1.1); filter: saturate(0) brightness(0);"
}}

        
`
export const PlayButton = styled.button`
  border: none;
  outline: none;
  border-radius: 50%;
  background: none;
  margin-left: 50%;

`
export const ProgressBar = styled.div`
  width: 100%;
  background-color: ${({ modal }) => (modal ? "#06101d" : "#fdfdfd")};
  height: 1px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    width: ${({ progress }) => progress}%;
    background-color: #f93737;
  }
  &::after {
    height: 2rem;
    width: 100%;
    position: absolute;
    content: "";
    left: 0;
    top: -1rem;
    cursor: pointer;
  }
`
export const ProgressBarContainer = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  position: relative;
`

export const Duration = styled.p`
  position: absolute;
  right: 0;
  top: 0;
  color: ${({ modal }) => (modal ? "#06101d" : "#fdfdfd")};
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
`

export const Progress = styled.p`
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ modal }) => (modal ? "#06101d" : "#fdfdfd")};
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
`
