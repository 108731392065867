import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const ContentWrapper = styled.div`
  @media (max-width: 1024px) {
    width: 90%;
  }
`

export const Article = styled.div`
  width: 30%;

  @media (max-width: 1024px) {
    width: 100vw;
  }
`
export const Photo = styled.img`
  margin-bottom: 2rem;
  width: 100%;
  height: 15rem;
  object-fit: cover;

  @media (max-width: 1024px) {
    width: 100%;
    margin-left: -7.5%;
    height: 18rem;
    object-fit: cover;
    margin-top: 2rem;
    display: block;
  }
`
export const Title = styled.h2`
  color: #fdfdfd;
  font-family: "Abril Fatface";
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 3.4rem;
  margin-bottom: 1.5rem;
  display: inline;

  @media (max-width: 1024px) {
    width: 85%;
  }
`
export const Text = styled.p`
  color: #fdfdfd;
  font-family: "Open Sans";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 2rem;
  display: inline;
  & * {
    font-weight: 600;
  }
`

export const MobileLead = styled.p`
  margin-top: 1rem;
  margin-bottom: 3rem;
  color: #fdfdfd;
  font-family: "Open Sans";
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  width: 85%;
`
