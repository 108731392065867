import React, { useState, useRef } from "react"
import {
  Wrapper,
  CloseButton,
  Mask,
  Text,
  CustomSidebar,
  TopFade,
  BottomFade,
  FadeWrapper,
  Photo,
  PaddingWrapper,
  LogoMobile,
} from "./styles"
import FocusLock from "react-focus-lock"

import { AnimatePresence } from "framer-motion"
import { fadeVariant, unfoldVariant } from "./styles/animations"
import Logo from "src/images/logo_big.png"
import { isMobile } from "react-device-detect"
import { useEffect } from "react"

const Modal = ({ open, handlerClose, children, photoSrc }) => {
  const [topFade, setTopFade] = useState(false)
  const [bottomFade, setBottomFade] = useState(false)
  const [focusEnabled, setFocusEnabled] = useState(false)

  const mask = useRef()
  const scrollBarRef = useRef()
  const scrollHandler = () => {
    if (scrollBarRef.current.scrollTop > 0) setTopFade(true)
    if (scrollBarRef.current.scrollTop === 0) setTopFade(false)
    if (scrollBarRef.current.scrollHeight > scrollBarRef.current.offsetHeight)
      setBottomFade(true)
    if (
      scrollBarRef.current.scrollTop + scrollBarRef.current.offsetHeight ===
      scrollBarRef.current.scrollHeight
    )
      setBottomFade(false)
  }

  const curtainCLick = e => {
    if (e.target !== mask.current) {
      return
    }
    return handlerClose()
  }

  function escapeHandler({ key }) {
    if (key === "Escape") {
      return handlerClose()
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", escapeHandler)
    window.addEventListener("keyup", enableFocus)

    return () => {
      window.removeEventListener("keyup", escapeHandler)
      window.removeEventListener("keyup", enableFocus)

    }
  }, [])


  function enableFocus({ key }) {
    if (key === "Tab") {    
      setFocusEnabled(true)                       
    }
  } 

  


  return (
    <AnimatePresence>
      {open && (
        <FocusLock>
          <Mask className={focusEnabled?'focusable':''}
            initial="exit"
            ref={mask}
            animate="enter"
            exit="exit"
            variants={fadeVariant}
            onClick={e => curtainCLick(e)}
          >
            <Wrapper
              initial="exit"
              animate="enter"
              exit="exit"
              variants={unfoldVariant}
              isPhoto={photoSrc}
            >
              <CloseButton tabindex="0" onClick={handlerClose}>
                X
              </CloseButton>
              {photoSrc && <Photo src={photoSrc} />}
              <PaddingWrapper>
                <FadeWrapper>
                  <TopFade
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={unfoldVariant}
                    show={topFade}
                  />
                  <BottomFade
                    initial="exit"
                    animate="enter"
                    exit="exit"
                    variants={unfoldVariant}
                    show={bottomFade}
                  />
                  <CustomSidebar
                    scrollableNodeProps={{ ref: scrollBarRef }}
                    onScroll={scrollHandler}
                  >
                    <Text>{children}</Text>
                    {isMobile && <LogoMobile src={Logo} />}
                  </CustomSidebar>
                </FadeWrapper>
              </PaddingWrapper>
            </Wrapper>
          </Mask>
        </FocusLock>
      )}
    </AnimatePresence>
  )
}

export default Modal
