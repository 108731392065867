import React, { useState } from "react"
import { Nav, NavElement, NavLink, NavList } from "./styles"
import Modal from "src/components/Modal/Modal"
import { Title, Text } from "src/components/ArticleRow/styles"

const animation = {
  exit: {
    x: "100%",
    transition: {
      ease: "easeInOut",
      duration: 0.7,
    },
  },
  enter: {
    x: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.7,
    },
  },
}

const Menu = ({ handleMenuToggle }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Nav initial="exit" animate="enter" exit="exit" variants={animation}>
      <NavList>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/poczatek-radia"
            partiallyActive={true}
          >
            początek radia
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/historia-ma-glos"
            partiallyActive={true}
          >
            HISTORIA ZAPISANA W DŹWIĘKU
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/teatr-wyobrazni"
            partiallyActive={true}
          >
            teatr wyobraźni
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/glosy-radiowego-teatru"
            partiallyActive={true}
          >
            Głosy radiowego teatru
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/glosy-pisarzy"
            partiallyActive={true}
          >
            głosy pisarzy
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/radiowa-filharmonia"
            partiallyActive={true}
          >
            radiowa filharmonia
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/sport"
            partiallyActive={true}
          >
            sport w polskim radiu
          </NavLink>
        </NavElement>
        <NavElement>
          <NavLink
            activeStyle={{ color: "#fafafa" }}
            fade
            onClick={handleMenuToggle}
            to="/radio-dzieciom"
            partiallyActive={true}
          >
            radio dzieciom
          </NavLink>
        </NavElement>
        <NavElement>
            <NavLink
              activeStyle={{ color: "#fafafa" }}
              fade
              onClick={handleMenuToggle}
              to="/do-uslyszenia-do-zobaczenia"
            >
              do usłyszenia, do zobaczenia
            </NavLink>
          </NavElement>
        <NavElement onClick={() => setModalOpen(true)}>
          <NavLink>źródła</NavLink>
          <Modal
            open={modalOpen}
            photoSrc={""}
            handlerClose={e => {
              e.stopPropagation()

              setModalOpen(false)
            }}
            audioArray={[]}
          >
            <Title
              style={{
                color: "#020417",
                display: "block",
                marginBottom: "3rem",
              }}
            >
              Źródła:
            </Title>

            <Text style={{ color: "#020417", display: "block" }}>
              <p>
                Zdjęcia pochodzą ze zbiorów: Polskiej Agencji Prasowej, East
                News, Agencji Forum, Narodowego Archiwum Cyfrowego oraz Archiwum
                Polskiego Radia
              </p>
              <p>
                Cytaty pochodzą z pracy Marcina Hermanowskiego "Radiofonia w
                Polsce. Zarys dziejów". Poznań 2018
              </p>
              <p>
                oraz książki Zbigniewa Chomicza "80 lat Polskiego Radia.
                Kalendarium". Warszawa 2005.
              </p>
            </Text>
          </Modal>
        </NavElement>
      </NavList>
    </Nav>
  )
}

export default Menu
