import React, { useState, useEffect } from "react"
import "./reset.css"
import DesktopLayout from "./desktop"
import MobileLayout from "./mobile"
import { useLocation } from "@reach/router"
import chapter1 from "src/images/backgroundMain.jpg"
import chapter1_mobile from "src/images/backgroundMain_mobile.jpg"
import chapter2 from "src/images/backgroundMain2.jpg"
import chapter2_mobile from "src/images/backgroundMain2_mobile.jpg"
import chapter3 from "src/images/backgroundMain3.jpg"
import chapter3_mobile from "src/images/backgroundMain3_mobile.jpg"
import chapter4 from "src/images/backgroundMain4.jpg"
import chapter4_mobile from "src/images/backgroundMain4_mobile.jpg"
import chapter5 from "src/images/backgroundMain5.jpg"
import chapter5_mobile from "src/images/backgroundMain5_mobile.jpg"
import chapter6 from "src/images/backgroundMain6.jpg"
import chapter6_mobile from "src/images/backgroundMain6_mobile.jpg"
import chapter7 from "src/images/backgroundMain7.jpg"
import chapter7_mobile from "src/images/backgroundMain7_mobile.jpg"
import chapter8 from "src/images/backgroundMain8.jpg"
import chapter8_mobile from "src/images/backgroundMain8_mobile.jpg"
import chapter9 from "src/images/backgroundMain9.jpg"
import chapter9_mobile from "src/images/backgroundMain9_mobile.jpg"
import notFound from "src/images/404.png"
import notFound_mobile from "src/images/404_mobile.png"

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.innerWidth < 1024 && setIsMobile(true)
    }
    let imageList;
    if ( window.innerWidth > 1024 ) {
      imageList = [chapter1, chapter2, chapter3, chapter4, chapter5, chapter6, chapter7, chapter8, chapter9]
    } else {
      imageList = [chapter1_mobile, chapter2_mobile, chapter3_mobile, chapter4_mobile, chapter5_mobile, chapter6_mobile, chapter7_mobile, chapter8_mobile, chapter9_mobile]
    }
    imageList.forEach((image) => {
      new Image().src = image
    });
  }, [])

  const getBg = () => {
    if (isMobile) {
      switch (pathname.replace(/\//g, "")) {
        case "poczatek-radia":
          return `url(${chapter1_mobile})`
        case "historia-ma-glos":
          return `url(${chapter2_mobile})`
        case "teatr-wyobrazni":
          return `url(${chapter3_mobile})`
        case "glosy-radiowego-teatru":
          return `url(${chapter4_mobile})`
        case "glosy-pisarzy":
          return `url(${chapter5_mobile})`
        case "radiowa-filharmonia":
          return `url(${chapter6_mobile})`
        case "sport":
          return `url(${chapter7_mobile})`
        case "radio-dzieciom":
          return `url(${chapter8_mobile})`
        case "do-uslyszenia-do-zobaczenia":
          return `url(${chapter9_mobile})`
        case "404":
          return `url(${notFound_mobile})`
        default:
          return `url(${chapter1_mobile})`
      }
    } else {
      switch (pathname.replace(/\//g, "")) {
        case "poczatek-radia":
          return `url(${chapter1})`
        case "historia-ma-glos":
          return `url(${chapter2})`
        case "teatr-wyobrazni":
          return `url(${chapter3})`
        case "glosy-radiowego-teatru":
          return `url(${chapter4})`
        case "glosy-pisarzy":
          return `url(${chapter5})`
        case "radiowa-filharmonia":
          return `url(${chapter6})`
        case "sport":
          return `url(${chapter7})`
        case "radio-dzieciom":
          return `url(${chapter8})`
        case "do-uslyszenia-do-zobaczenia":
          return `url(${chapter9})`
        case "404":
          return `url(${notFound})`
        default:
          return `url(${chapter1})`
      }
    }
  }

  const getTitle = () => {
    switch (pathname.replace(/\//g, "")) {
      case "poczatek-radia":
        return "Początek radia"
      case "historia-ma-glos":
        return "Historia ma głos"
      case "glosy-radiowego-teatru":
        return "Głosy radiowego teatru"
      case "teatr-wyobrazni":
        return "Teatr wyobraźni"
      case "radiowa-filharmonia":
        return "Radiowa Filharmonia"
      case "glosy-pisarzy":
        return "Głosy pisarzy"
      case "sport":
        return "Sport w Polskim Radiu"
      case "radio-dzieciom":
        return "Radio Dzieciom"
      case "do-uslyszenia-do-zobaczenia":
        return `Do usłyszenia, do zobaczenia`
      default:
        return "95 Lat Polskiego Radia"
    }
  }

  if (isMobile) return <MobileLayout children={children} bg={getBg()} />
  return <DesktopLayout children={children} bg={getBg()} title={getTitle()} />
}

export default Layout
