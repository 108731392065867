// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-do-uslyszenia-do-zobaczenia-js": () => import("./../../../src/pages/do-uslyszenia-do-zobaczenia.js" /* webpackChunkName: "component---src-pages-do-uslyszenia-do-zobaczenia-js" */),
  "component---src-pages-glosy-pisarzy-js": () => import("./../../../src/pages/glosy-pisarzy.js" /* webpackChunkName: "component---src-pages-glosy-pisarzy-js" */),
  "component---src-pages-glosy-radiowego-teatru-js": () => import("./../../../src/pages/glosy-radiowego-teatru.js" /* webpackChunkName: "component---src-pages-glosy-radiowego-teatru-js" */),
  "component---src-pages-historia-ma-glos-js": () => import("./../../../src/pages/historia-ma-glos.js" /* webpackChunkName: "component---src-pages-historia-ma-glos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poczatek-radia-js": () => import("./../../../src/pages/poczatek-radia.js" /* webpackChunkName: "component---src-pages-poczatek-radia-js" */),
  "component---src-pages-radio-dzieciom-js": () => import("./../../../src/pages/radio-dzieciom.js" /* webpackChunkName: "component---src-pages-radio-dzieciom-js" */),
  "component---src-pages-radiowa-filharmonia-js": () => import("./../../../src/pages/radiowa-filharmonia.js" /* webpackChunkName: "component---src-pages-radiowa-filharmonia-js" */),
  "component---src-pages-sport-js": () => import("./../../../src/pages/sport.js" /* webpackChunkName: "component---src-pages-sport-js" */),
  "component---src-pages-teatr-wyobrazni-js": () => import("./../../../src/pages/teatr-wyobrazni.js" /* webpackChunkName: "component---src-pages-teatr-wyobrazni-js" */)
}

