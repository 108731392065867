import React, { useState } from "react"
import { MenuButton, Wrapper, WrapperScroll } from "./styles"
import HamburgerMenu from "react-hamburger-menu"
import Menu from "./Menu"
import { AnimatePresence } from "framer-motion"

const Layout = ({ children, bg }) => {
  const [isMenuVisible, setMenuVisible] = useState(false)

  const handleMenuToggle = e => {
    setMenuVisible(!isMenuVisible)
  }
  return (
    <Wrapper bg={bg}>
      <MenuButton>
        <HamburgerMenu
          isOpen={isMenuVisible}
          menuClicked={() => setMenuVisible(!isMenuVisible)}
          width={30}
          height={20}
          strokeWidth={3}
          rotate={0}
          color="#fff"
          borderRadius={0}
          animationDuration={0.5}
        />
      </MenuButton>
      <AnimatePresence>
        {isMenuVisible && <Menu handleMenuToggle={handleMenuToggle} />}
      </AnimatePresence>
      <WrapperScroll>{children}</WrapperScroll>
    </Wrapper>
  )
}

export default Layout
