import React from "react"
import { Text } from "./styles"
import ClampLines from "react-clamp-lines"
const AudioDescription = ({ content, noBreak, key, modal }) => {
  if (content.length > 0)
    return (
      <Text modal={modal}>
        <>
          {!noBreak && <br />}
          <ClampLines
            tabindex="0"
            id={key}
            text={`${content}`}
            lines={modal ? 999999 : 4}
            ellipsis={"..."}
            moreText={"v"}
            lessText={"ʌ"}
            cutoff={12}
          />
        </>
      </Text>
    )
  return null
}

export default AudioDescription
